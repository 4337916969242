<template>
  <div class="login-content d-flex flex-column pt-lg-0 pt-12 px-10">
    <!--begin::Logo-->
    <router-link :to="{ name: 'dashboard' }" v-slot="{ href, navigate }">
      <a :href="href" @click="navigate" class="login-logo pb-xl-20 pb-10">
        <img
          src="media/logos/logo-4.png"
          class="max-h-70px"
          alt="Central Core"
        />
      </a>
    </router-link>
    <!--end::Logo-->
    <!--begin::Signin-->
    <div class="login-form">
      <form
        class="form"
        novalidate="novalidate"
        id="kt_login_forgot_form"
        @submit.stop.prevent="onSubmitForgot()"
      >
        <!--begin::Title-->
        <div class="pb-10 pb-lg-15">
          <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            Mot de passe oublié ?
          </h3>
          <p class="text-muted font-weight-bold font-size-h4">
            Encodez votre adresse email afin de réinitialiser votre mot de passe
          </p>
        </div>
        <!--end::Title-->
        <!--begin::Form group-->
        <div class="form-group">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
            type="email"
            v-model="model.email"
            placeholder="Email"
            name="email"
            autocomplete="off"
          />
        </div>
        <!--end::Form group-->
        <!--begin::Form group-->
        <div class="form-group d-flex flex-wrap">
          <button
            ref="kt_login_forgot_submit"
            id="kt_login_forgot_form_submit_button"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          >
            Réinitialiser
          </button>
          <router-link :to="{ name: 'login' }" v-slot="{ href, navigate }">
            <a
              :href="href"
              @click="navigate"
              class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              id="kt_login_forgot_cancel"
            >
              Annuler
            </a>
          </router-link>
        </div>
        <!--end::Form group-->
      </form>
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

export default {
  name: "forgotPassword",
  data() {
    return {
      state: "forgot",
      model: {
        email: ""
      }
    };
  },
  mounted() {
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    this.fv = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            },
            emailAddress: {
              message: "Veuillez encoder une adresse email valide"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    onSubmitForgot() {
      this.fv.validate();

      // todo Simon : Fixer
      this.fv.on("core.form.valid", () => {
        // // clear existing errors
        // this.$store.dispatch(LOGOUT);
        // // set spinner to submit button
        // const submitButton = this.$refs["kt_login_forgot_submit"];
        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        // // dummy delay
        // setTimeout(() => {
        //   // send register request
        //   this.$store
        //     .dispatch(REGISTER, {
        //       email: email,
        //       password: password
        //     })
        //     .then(() => this.$router.push({ name: "dashboard" }));
        //   submitButton.classList.remove(
        //     "spinner",
        //     "spinner-light",
        //     "spinner-right"
        //   );
        // }, 2000);
      });
    }
  }
};
</script>
